@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

* {
    font-family: 'Inter', sans-serif;
    font-family: 'Bebas Neue', cursive;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }  
  