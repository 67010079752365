@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
.react-pdf__Page {
    margin-top: 10px;
  }
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }
  
  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  
  /* For all pages */
  .all-page-container {
    height: 100%;
    max-height: 500px;
    overflow: auto;
  }
  
* {
    font-family: 'Inter', sans-serif;
    font-family: 'Bebas Neue', cursive;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }  
  
